import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Koncepty pojetí biologického druhu" />
    <h1>Koncepty pojetí biologického druhu</h1>
    <p>Podle Maydena (1998) existuje 22&nbsp;různ&yacute;ch pojet&iacute; a konceptů jak definovat druh v biologii. Ani jedna v&scaron;ak nen&iacute; dokonal&aacute; a existuj&iacute; v&yacute;jimky. Pro &uacute;čely biogeografie jsou pak nejdůležitěj&scaron;&iacute; tyto 3&nbsp;koncepce:</p>
<ul>
<li><strong>morfologick&yacute; koncept druhu (MSC)</strong></li>
<li><strong>biologick&yacute; koncept druhu (BSC)</strong></li>
<li><strong>fylogenetick&yacute; koncept druhu (PSC)</strong></li>
</ul>
<h2>Koncept morfologick&eacute;ho druhu</h2>
<p>V <strong>konceptu morfologick&eacute;ho druhu</strong> (zkratka <strong>MSC</strong> vych&aacute;zej&iacute;c&iacute; z angličtiny) je druh definov&aacute;n jako skupina jedinců, kteř&iacute; jsou morfologicky odli&scaron;iteln&iacute; od jin&yacute;ch takov&yacute;ch skupin.</p>
<p><strong>V&yacute;hoda</strong> spoč&iacute;v&aacute; předev&scaron;&iacute;m v tom, že takov&yacute; koncept je aplikovateln&yacute; na fosiln&iacute; materi&aacute;l.</p>
<p><strong>Nev&yacute;hodou</strong> je v&yacute;skyt druhů polytypick&yacute;ch (1 pohlav&iacute; se může vyskytovat ve 2 či v&iacute;ce morfologick&yacute;ch form&aacute;ch) a kryptick&yacute;ch (druh skryt&yacute;, na prvn&iacute; pohled nerozli&scaron;iteln&yacute; od jin&eacute;ho).</p>
<h2>Koncept biologick&eacute;ho druhu</h2>
<p>V <strong>konceptu biologick&eacute;ho druhu</strong> (zkratka <strong>BSC</strong> vych&aacute;zej&iacute;c&iacute; z angličtiny) je druh definov&aacute;n jako skupina populac&iacute;, kter&aacute; je reprodukčně izolovan&aacute; od ostatn&iacute;ch populac&iacute;. Tato izolace je dvoj&iacute;ho typu:</p>
<ul>
<li><strong>Izolace prezygotick&aacute;</strong> (nedojde ani ke zplozen&iacute; jedince)
<ul>
<li><strong>předkopulačn&iacute; </strong>(vz&aacute;jemn&aacute; sexu&aacute;ln&iacute; nepřitažlivost různ&yacute;ch druhů, různ&aacute; doba vhodn&aacute; pro p&aacute;řen&iacute; nebo inkompatibilita kopulačn&iacute;ch org&aacute;nů aj.)</li>
<li><strong>postkopulačn&iacute;</strong> (nekompatibilita gamet, nedojde k oplozen&iacute;)</li>
</ul>
</li>
<li><strong>Izolace postzygotick&aacute;</strong> (neživotaschopnost hybridů, jejich sterilita aj.)</li>
</ul>
<p>I s t&iacute;mto pojet&iacute;m druhu souvis&iacute; <strong>několik probl&eacute;mů</strong>. Předev&scaron;&iacute;m jde o &scaron;patnou aplikovatelnost na fosiln&iacute; materi&aacute;ly. D&aacute;le je obt&iacute;žně aplikovateln&eacute; na klon&aacute;ln&iacute; organismy, kde by dle t&eacute;to definice byl každ&yacute; jedinec druhem. Problematick&aacute; je i biologick&aacute; hybridizace, kdy mohou vzniknout životaschopn&iacute; (a vz&aacute;cně i plodn&iacute;) kř&iacute;ženci.</p>
<h2>Koncept fylogenetick&eacute;ho druhu</h2>
<p>V <strong>konceptu fylogenetick&eacute;ho druhu</strong> (zkratka <strong>PSC</strong> vych&aacute;zej&iacute;c&iacute; z angličtiny) je druh definov&aacute;n jako skupinu jednotlivců, kde v&scaron;ichni sd&iacute;l&iacute; <strong>apomorfn&iacute;</strong> (neboli odvozen&yacute;) znak, kter&yacute; nen&iacute; př&iacute;tomen u jejich předků či u př&iacute;buzn&yacute;ch druhů.</p>
<p>Apomorfn&iacute; (či odvozen&yacute;) znak může b&yacute;t morfologick&yacute; i genetick&yacute;, kdy se pak jedn&aacute; o nějakou unik&aacute;tn&iacute; genetickou sekvenci.</p>
<p>Fylogenetick&yacute; koncept druhu je minim&aacute;ln&iacute; a jasně diagnostikovan&aacute; jednotka fylogeneze. Ač jasně definovan&yacute;, neshoduje se plně s re&aacute;lnou situac&iacute;.</p>
<h2>Jednotky niž&scaron;&iacute; než druh</h2>
<p>Kvůli potřebě odli&scaron;en&iacute; skupin populac&iacute; v r&aacute;mci 1 druhu od jin&eacute; skupiny populac&iacute; t&eacute;hož druhu se rozli&scaron;uj&iacute; jednotky niž&scaron;&iacute; než druh:</p>
<ul>
<li><strong>poddruh</strong> (je&scaron;tě jde o taxonomickou jednotku)</li>
<li><strong>variety, morfy, ekotypy</strong> (niž&scaron;&iacute;, netaxonomick&eacute; jednotky li&scaron;&iacute;c&iacute; se např&iacute;klad adaptacemi na sv&aacute; specifick&aacute; stanovi&scaron;tě, ale nejsou reprodukčně izolov&aacute;ny, v&iacute;ce se vyskytuj&iacute; v botanice)</li>
</ul>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Koncepty pojetí biologického druhu</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/evolucni/"><button className="inv">&larr; Evoluční biogeografie</button></Link>
    <Link to="/biogeografie/evolucni/speciace/"><button className="inv">Speciace &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
